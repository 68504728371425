import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/auth",
  },
  {
    path: "/auth",
    component: () => import("../views/auth/Auth.vue"),
    children: [
      {
        path: "",
        name: "Sign In",
        component: () => import("../views/auth/children/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "Sign Up",
        component: () => import("../views/auth/children/SignUp.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/auth",
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
