<template>
  <v-app id="app">
    <v-main class="background-image">
      <router-view />
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import SnackBar from "./components/app/SnackBar";

export default {
  name: "App",

  components: {
    SnackBar,
  },
};
</script>

<style src="./styles/style.scss" lang="scss" />
