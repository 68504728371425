import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: "#ff6700",
        secondary: "#FFB300",
        info: "#2196F3",
        success: "#4CAF50",
        error: "#FF5252",
        warning: "#FFC107",
        background: "#f8f7fa",
        altback: "#FFFFFF",
        surface: "#FFFFFF",
        accent: "#ebe9f0",
        overtext: "#000000",
        subtext: "#9b9ca2",
      },
    },
  },

  lang: {
    locales: { pt },
    current: "pt",
  },
});
