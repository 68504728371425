import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    defaultURL: "https://arenajoguefacil.com.br/#/",
  },

  getters: {
    defaultURL: ({ defaultURL }) => defaultURL,
  },

  mutations: {},

  actions: {},

  modules: {},
});
